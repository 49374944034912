import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CSectTitle,
  CKv,
  CGoogleMap,
  CBgMedia,
  CVerticalMedia,
  AssetImage,
  CBtn,
  CPetternMedia,
  CSearch,
  CTab,
  CTabItem,
  CNewsList,
  CBtnList,
} from '../components/_index';
import '../assets/_sass/page/home.scss';
import infoChoice from '../utils/info-choice';
import infoGet from '../utils/info-get';
import releaseChoice from '../utils/release-choice';
import releaseGet from '../utils/release-get';

// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEO ogtype={'website'} />
      <CKv
        video={{ src: '/assets/movie/kv.mp4' }}
        videoSp={{ src: '/assets/movie/kv__sp.mp4' }}
      />
      <CSearch formId="top" />
      <section className="l_sect u_pt60_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: 'お知らせ',
            }}
          />
          <CTab defaultKey={0}>
            <CTabItem tabKey={0} title="最新情報">
              <CNewsList data={infoChoice(infoGet(), ['トップページ'], 5)} />
              <CBtnList
                data={[
                  {
                    label: 'お知らせ一覧',
                    color: 'borderBlack',
                    link: {
                      href: '/information/',
                    },
                  },
                ]}
              />
            </CTabItem>
            <CTabItem tabKey={1} title="ニュースリリース">
              <CNewsList data={releaseChoice(releaseGet(), 5)} />
              <CBtnList
                data={[
                  {
                    label: 'ニュースリリース一覧',
                    color: 'borderBlack',
                    link: {
                      href: '/release/',
                    },
                  },
                ]}
              />
            </CTabItem>
          </CTab>
        </LWrap>
      </section>
      <LWrap>
        <CBgMedia
          data={[
            {
              img: {
                src: '/assets/images/home/img_stay.png',
              },
              imgSp: {
                src: '/assets/images/home/img_stay__sp.png',
              },
              title: {
                en: 'STAY',
                ja: '泊まる',
              },
              catchCopy: (
                <>
                  ゆったりとした空間で、
                  <br />
                  くつろぎのひとときを
                </>
              ),
              topLink: {
                label: '宿泊',
                link: {
                  href: '/stay/',
                },
              },
              linkList: [
                {
                  label: 'エグゼクティブラウンジ',
                  link: {
                    href: '/stay/floor/lounge/',
                  },
                },
                {
                  label: '客室',
                  link: {
                    href: '/stay/#suite',
                  },
                },
                {
                  label: '宿泊プラン一覧',
                  link: {
                    href: '/stay/plan/',
                  },
                },
              ],
            },
            {
              img: {
                src: '/assets/images/home/img_restaurant.png',
              },
              imgSp: {
                src: '/assets/images/home/img_restaurant__sp.png',
              },
              title: {
                en: 'RESTAURANT',
                ja: '味わう',
              },
              catchCopy: (
                <>
                  多彩な味わいが織り成す
                  <br />
                  贅沢な饗宴を
                </>
              ),
              topLink: {
                label: 'レストラン＆バー',
                link: {
                  href: '/restaurants/',
                },
              },
              linkList: [
                {
                  label: '個室',
                  link: {
                    href: '/restaurants/private/',
                  },
                },
                {
                  label: 'テイクアウト',
                  link: {
                    href: '/restaurants/iki/',
                  },
                },
              ],
            },
          ]}
        />
        <div className="p_topBgWrapper">
          <CVerticalMedia
            exClass="u_mb0"
            data={[
              {
                img: {
                  src: '/assets/images/home/img_celebration.png',
                },
                imgSp: {
                  src: '/assets/images/home/img_celebration__sp.png',
                },
                title: {
                  en: 'CELEBRATION',
                  ja: 'お祝い',
                },
                catchCopy: {
                  title: (
                    <>
                      雅趣に富む
                      <br />
                      人生の節目を華やかに
                    </>
                  ),
                },
                topLink: {
                  label: 'お祝い',
                  link: {
                    href: '/oiwai/',
                  },
                },
              },
              {
                img: {
                  src: '/assets/images/home/img_anniversary.png',
                },
                imgSp: {
                  src: '/assets/images/home/img_anniversary__sp.png',
                },
                title: {
                  en: 'ANNIVERSARY',
                  ja: '記念日',
                },
                catchCopy: {
                  title: (
                    <>
                      大切な一日を
                      <br />
                      粋なおもてなしでお手伝い
                    </>
                  ),
                },
                topLink: {
                  label: '記念日・プロポーズ',
                  link: {
                    href: '/anniversary/',
                  },
                },
              },
            ]}
          />
        </div>
        <div className="p_topBgWrapper02">
          <CVerticalMedia
            exClass="u_mb0"
            data={[
              {
                img: {
                  src: '/assets/images/home/img_wedding.png',
                },
                imgSp: {
                  src: '/assets/images/home/img_wedding__sp.png',
                },
                title: {
                  en: 'WEDDING',
                  ja: 'ウエディング',
                },
                catchCopy: {
                  title: (
                    <>
                      光り輝く、
                      <br />
                      おふたりらしいウエディングを
                    </>
                  ),
                },
                topLink: {
                  label: 'ウエディング',
                  link: {
                    href: 'https://www.rph.co.jp/wedding/',
                    blank: true,
                  },
                },
              },
            ]}
          />
        </div>
        <CBgMedia
          exClass="u_mb0"
          data={[
            {
              img: {
                src: '/assets/images/home/img_banquet.png',
              },
              imgSp: {
                src: '/assets/images/home/img_banquet__sp.png',
              },
              title: {
                en: 'BANQUET',
                ja: '宴会・会議',
              },
              catchCopy: (
                <>
                  多種多様な会場が、
                  <br />
                  集う人数に合わせた演出を
                  <br />
                  叶えます
                </>
              ),
              topLink: {
                label: '宴会・会議',
                link: {
                  href: '/banquet/',
                },
              },
            },
          ]}
        />
        <section className="l_sect">
          <LWrap>
            <CSectTitle
              title={{
                en: 'ABOUT US',
                ja: 'ホテルについて',
              }}
            />
            <CPetternMedia
              data={[
                {
                  img: {
                    src: '/assets/images/home/img_chictokyo.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_chictokyo.png',
                  },
                  title: <>CHIC TOKYO STAY</>,
                  catchCopy: <>粋な街の、意気なおもてなし</>,
                  text: (
                    <>
                      <p>
                        TOKYOと東京、革新と伝統、
                        <br />
                        新しさと懐かしさが交差する街“日本橋”。
                        <br />
                        ロイヤルパークホテルは、
                        <br />
                        その息づかいとぬくもりが
                        <br className="u_sp" />
                        寄り添う街にあります。
                        <br />
                        <br className="u_sp" />
                        この街で、心からのおもてなし。
                        <br />
                        和魂洋才のホスピタリティで、
                        <br className="u_sp" />
                        あなたをお迎えいたします。
                        <br />
                        <br />
                        粋なホテルの、心意気。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CVerticalMedia
              exClass="u_mb0"
              data={[
                {
                  imgSize: 'Small',
                  img: {
                    src: '/assets/images/home/img_access.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_access__sp.png',
                  },
                  title: {
                    en: 'ACCESS',
                    ja: '好立地',
                  },
                  catchCopy: {
                    title: (
                      <>
                        駅直結の好アクセス
                        <br />
                        ビジネス・観光の拠点に
                      </>
                    ),
                    text: (
                      <>
                        半蔵門線「水天宮前」駅直結、
                        <br />
                        羽田・成田空港にも直行便があり
                        <br />
                        東京駅より車で約10分
                      </>
                    ),
                  },
                  topLink: {
                    label: 'アクセス',
                    link: {
                      href: '/about/access/',
                    },
                  },
                },
                {
                  imgSize: 'Small',
                  img: {
                    src: '/assets/images/home/img_culture.png',
                  },
                  imgSp: {
                    src: '/assets/images/home/img_culture__sp.png',
                  },
                  title: {
                    en: 'CULTURE',
                    ja: '楽しむ',
                  },
                  catchCopy: {
                    title: (
                      <>
                        街を、楽しむ。
                        <br />
                        日本橋・人形町
                      </>
                    ),
                    text: (
                      <>
                        歴史と伝統を持った粋な下町「日本橋・人形町」の文化に触れ、
                        <br />
                        “ルームキーを持って”街を散策すると、老舗の魅力だけでなく素敵な特典も。
                        <br />
                        隅田川沿いのお散歩や七福神巡りなど、ご滞在中のひとときを存分にお楽しみください。
                      </>
                    ),
                  },
                  topLink: {
                    label: '街を、楽しむ',
                    link: {
                      href: '/about/enjoy_the_city/',
                    },
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </LWrap>
    </Layout>
  );
};

export default IndexPage;
